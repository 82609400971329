.footer {
    position: relative;
    width: 100%;
    background: #3586ff;
    min-height: 100px;
    // padding: 20px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.no-content {
        min-height: 0;
        width: 100vw;
        background: transparent;
        flex-direction: row;
    }
}

.footer p {
    color: #fff;
    margin: 15px 0 10px 0;
    font-size: 1rem;
    font-weight: 300;
}

.wave {
    position: absolute;
    top: -100px;
    left: 0;
    width: 100%;
    height: 100px;
    // background: url("https://i.ibb.co/wQZVxxk/wave.png");
    background-size: 1000px 100px;

    &.dark {
        // background: url('../../../assets/svg/wave-black.png');
    }

    &.light {
        // background: url('../../../assets/svg/wave-white.png');
    }
}

.wave#wave1,
.wave.dark#wave1,
.wave.light#wave1,
    {
    z-index: 1000;
    opacity: 1;
    bottom: 0;
    animation: animateWaves 4s linear infinite;
}

.wave#wave2,
.wave.dark#wave2,
.wave.light#wave2,
    {
    z-index: 999;
    opacity: 0.5;
    bottom: 10px;
    animation: animate 4s linear infinite !important;
}

.wave#wave3,
.wave.dark#wave3,
.wave.light#wave3,
    {
    z-index: 1000;
    opacity: 0.2;
    bottom: 15px;
    animation: animateWaves 3s linear infinite;
}

.wave#wave4,
.wave.dark#wave4,
.wave.light#wave4,
    {
    z-index: 999;
    opacity: 0.7;
    bottom: 20px;
    animation: animate 3s linear infinite;
}

@keyframes animateWaves {
    0% {
        background-position-x: 1000px;
    }

    100% {
        background-positon-x: 0px;
    }
}

@keyframes animate {
    0% {
        background-position-x: -1000px;
    }

    100% {
        background-positon-x: 0px;
    }
}