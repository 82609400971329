@import '../../../styles/_colors.scss';
@import '../../../styles/_layout.scss';

.tabs-container {
    width: 100%;

    // &.tabs {
    //     text-align: center;

    // }
}


.tabs {
    @extend .no-scrollbar;
    position: relative;
    width: 100%;
    // height: 2rem;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-x: auto;
    white-space: nowrap;

    border-bottom: 1px solid #88888888;
    // justify-content: space-between;
    // margin-bottom: 1rem;

    &.centerd {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .tab {
        // flex-grow: 1;
        // padding: calc(1rem - 1px);
        padding: calc(0.7rem + 0.5px);
        // padding-right: 1rem;
        // height: 2rem;
        // line-height: 2rem;
        border-bottom: 1px solid transparent;
        text-align: center;
        font-size: 0.8rem;
        cursor: pointer;



        &.active {
            color: $col-active;
            border-bottom: 2px solid $col-active;

            &.dark {
                text-shadow: 0px 0px 5px #000000;
            }

            &.light {
                text-shadow: 0px 0px 10px #AAAAAA;
            }
        }
    }
}

.tab.active {
    color: #0067FF;
    /* Set your active tab color */
}

.underline {
    height: 1px;
    position: absolute;
    top: -2px;
    background-color: #0067FF;
    /* Set your underline color */
    transition: all 5s ease;
}

.underline.first {
    transform: translateX(0);
}

.underline.second {
    transform: translateX(100%);
}

.underline.third {
    transform: translateX(200%);
}

.tab-content {
    height: fit-content;
    max-height: calc(100vh - 3rem);
    overflow-y: auto;
    padding-bottom: 2rem;
    // margin-top: 20px;
    // border-bottom: 1px solid #88888888;
}

.content {
    // display: none;
}

.content.active {
    display: block;
    height: fit-content;
    max-height: 100%;
}