// @import '../../../node_modules/react-grid-layout/css/styles.css';
// @import '../../../node_modules/react-resizable/css/styles.css';

.react-grid-item {
    @extend .card;
    @extend .card-1;
}
.react-grid-item.react-grid-placeholder {
    background: #0067FF !important;
    // background: transparent!important;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.grid-tile {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}