// Toast.scss
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-10px);
    }
}

.toast-enter {
    animation: fadeIn 0.3s forwards;
}

.toast-exit {
    animation: fadeOut 0.3s forwards;
}