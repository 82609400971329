@import './styles/_card.scss';
@import './styles/_colors.scss';
@import './styles/_layout.scss';
@import './styles/_misc.scss';
@import './styles/responsive.scss';
// components
@import './styles/components/Accordion.scss';
@import './styles/components/AppMenu.scss';
@import './styles/components/AppTopBar.scss';
@import './styles/components/Async.scss';
@import './styles/components/Loader.scss';
// #rd party overrides
@import './styles/overrides/react-split-pane.scss';
@import './styles/overrides/react-grid-layout.scss';
@import './styles/overrides/@editorjs.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Mukta', sans-serif;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  // font-weight: 300;
}

.App {
  // text-align: center;
  overflow-x: hidden;

  &.dark {
    // background-color: #222222;
    background-color: #0D1117;
    color: #FAFAFA;
  }

  &.light {
    background-color: #FAFAFA;
    // color: #222222;
    color: #0D1117;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: transparent;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

hr {
  border-color: #88888888;
}

.hlsj {
  background-color: transparent!important;
}