.slideshow {
    position: relative;
    width: 100%;
    height: 100%;
    // overflow-x: auto;
}

.slideshow-content {
    display: flex;
    overflow-x: hidden;
    height: 100%;
    transition: transform 0.3s;
}

.slide {
    flex: 0 0 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
        background-color: #000;
        color: #fff;
    }
}

.slide-panel {
    width: calc(100vw - 4rem);
    height: 24rem;
    margin: 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    align-items: center;
    justify-content: space-between;

    &.light {
        background-color: #FFFFFF;
        color: #000;
    }

    &.dark {
        background-color: #000;
        color: #FFFFFF;
    }

    &.primary {
        background-color: #0067FF;
        color: #FFFFFF;
    }
}

.dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
}

.dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #aaaaaa88;
    margin: 0 0.25rem;
    cursor: pointer;

    &.active {
        background-color: #EEEEEE;
        box-shadow: 0px 10px 15px -3px #00ADEE88;
        border-radius: 1px solid #0067FF;
    }
}