.theme-icon {
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 8px;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}