.sliding-steps-container {
    overflow: hidden;
    /* Prevents overflow during transitions */
    width: 100%;
    /* Set the desired width */
    position: relative;
}

.sliding-steps-wrapper {
    display: flex;
    /* Align items horizontally */
    transition: transform 0.5s ease-in-out;
    /* Smooth transition */
}

.sliding-step {
    min-width: 100%;
    /* Each step takes full width */
    transition: opacity 0.5s ease-in-out;
    /* Smooth fade transition */
}