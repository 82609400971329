.content-rating {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rating-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.content-type {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    transition: all 0.3s ease;
    width: 60px;
    height: 60px;
    line-height: 60px;
}

.content-type.active {
    border-color: var(--primary-color);
}

.age-rating {
    font-size: 20px;
    font-weight: bold;
}